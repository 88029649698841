<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">Luottotiedot</div>

    <v-row dense>
      <v-col>
        <v-btn-toggle mandatory color="blue" v-model="tab">
          <v-btn value="0"> Hae </v-btn>
          <v-btn value="1"> Vanhat haut </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-card class="mt-2">
      <!-- Search -->
      <div v-if="tab == 0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="headline">Hae luottotietoja</v-list-item-title>
            <v-list-item-subtitle class="text-wrap error--text">
              Huom! Luottotietojen hakeminen on maksullinen ominaisuus. Hakeaksesi luottotietoja
              sinun tulee olla Ropo One-palvelun rekisteröitynyt asiakas.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-form ref="form">
          <v-container>
            <h3>Hakijan tiedot</h3>

            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="data.querier_name"
                  :rules="required"
                  label="Hakijan nimi"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model.trim="data.querier_ssn"
                  :rules="required"
                  label="Henkilötunnus"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <h3>Haettavan tiedot</h3>

            <v-row dense>
              <v-col cols="12" md="3" lg="2">
                <v-radio-group v-model="isCompany" :mandatory="true" hide-details>
                  <v-radio label="Yksityishenkilö" :value="false"></v-radio>
                  <v-radio label="Yritys" :value="true"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model.trim="data.govid"
                  :rules="required"
                  label="Sotu tai Y-tunnus"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- Reason -->
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-select
                  v-model="data.creditinfo_reason"
                  :items="reasons"
                  item-value="val"
                  item-text="text"
                  :rules="required"
                  label="Valitse haun peruste"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>

              <!-- If individual -->
              <v-col v-if="!isCompany" cols="12" sm="6" md="4" lg="3" xl="2">
                <v-select
                  v-model="data.creditinfo_status"
                  :items="individualStatuses"
                  item-value="val"
                  item-text="text"
                  :rules="required"
                  label="Valitse haun tyyppi"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>

              <!-- If company -->
              <v-col v-if="isCompany" cols="12" sm="6" md="4" lg="3" xl="2">
                <v-select
                  v-model="data.creditinfo_status"
                  :items="companyStatuses"
                  item-value="val"
                  item-text="text"
                  :rules="required"
                  label="Valitse haun tyyppi"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-show="isCompany" dense>
              <v-col cols="12">
                <small
                  >Mikäli haluat hakea muita yrityksen luottoraportteja, voit hakea niitä suoraan
                  Ropo24 palvelun käyttöliittymän kautta.</small
                >
              </v-col>
            </v-row>

            <v-btn color="info" class="mr-4 mt-4" @click="submit">Hae luottotiedot</v-btn>
          </v-container>
        </v-form>
      </div>

      <!-- Old reports -->
      <div v-if="tab == 1">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="headline">Haetut luottotietoraportit</v-list-item-title>
            <v-list-item-subtitle class="text-wrap success--text">
              Voit ladata hakemasi dokumentit tätä kautta tarvittaessa uudelleen. Dokumentit eivät
              kuitenkaan päivity ja niistä saatava tieto on aina hakupäivän mukainen. Toiminto ei
              ole maksullinen.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-row class="justify-center">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              solo
              append-icon="mdi-magnify"
              label="Suodata"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-col>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="reports"
            :footer-props="globalValues.footerProps"
            :items-per-page="getDefaultTableRows()"
          >
            <!-- date -->
            <template #[`item.createdAt`]="{ item }">
              <span>{{ formatDate(item.createdAt) }}</span>
            </template>
            <!-- Actions -->
            <template #[`item.actions`]="{ item }">
              <v-icon title="Lataa" small @click="downloadItem(item)">mdi-download</v-icon>
            </template>
            <!-- No data -->
            <template #no-data>
              <h2>Ei haettuja luottotietoja</h2>
            </template>
          </v-data-table>
        </v-col>
      </div>
    </v-card>
  </div>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import { apiAgent } from "../../services/apiAgent";
import globalValues from "@/configs/globalValues";
import { mapMutations } from "vuex";

export default {
  title: "Hae luottotietoja",

  mixins: [mixins],

  data() {
    return {
      headers: [
        { text: "Haun päivämäärä", value: "createdAt" },
        { text: "Haettu y - tai henkilötunnus", value: "govid" },
        {
          text: "Toiminnot",
          value: "actions",
          sortable: false,
        },
      ],
      search: "",
      tab: 0,
      tabs: ["Hae", "Vanhat haut"],
      isCompany: false,
      reports: [],
      reasons: [
        { val: "1", text: "Luoton myöntäminen" },
        { val: "2", text: "Luotonvalvonta" },
        { val: "3", text: "Perinnän suunnittelu" },
        {
          val: "5",
          text: "Vastuuhenkilöasema (yrityksen tai vastuuhenkilön arviointi)",
        },
        { val: "6", text: "Henkilön omalla suostumuksella" },
        { val: "7", text: "Huoneenvuokrasopimus" },
        { val: "8", text: "Työnhakijan tai työntekijän arviointi" },
        {
          val: "9",
          text: "Viranomaisen lakiin perustuva tiedonsaantioikeus tai viranomaistuen myöntäminen",
        },
        { val: "A", text: "Takaus tai pantin anto" },
        {
          val: "B",
          text: "Sopimusehtojen määrittely jos sopimuksen tekemisestä ei voi kieltäytyä",
        },
        {
          val: "C",
          text: "Todistus velvoitteiden hoitokyvystä ja rahanpesun ehkäiseminen",
        },
      ],
      individualStatuses: [
        { val: "credit", text: "Luottotiedot 0,65 e" },
        { val: "creditcplus", text: "Luottotiedot ja yritysyhteydet 0,95 e" },
      ],
      companyStatuses: [
        // { val: "krbasic ", text: "Kaupparekisteriraportti 0,50 e" },
        // { val: "krplus ", text: "KR + Vastuuhenkilöt 0,80 e " },
        // { val: "rating", text: "Rating-paketti 9,60 e" },
        // {
        //   val: "creditcplus",
        //   text: "Luottotiedot + Vastuuhenkilöt + Yritysyhteydet 2,10 e",
        // },
        // { val: "krplus2", text: "KR + Vastuuhenkilöt + Yritysyhteydet 1,20 e" },
        { val: "credit", text: "Luottotiedot + Vastuuhenkilöt 1,80 e" },
      ],
      data: {
        govid: "",
        querier_name: "",
        querier_ssn: "",
        creditinfo_reason: "7",
        creditinfo_status: "credit",
      },
      emailRules: [(v) => /.+@.+\..+/.test(v) || "Email ei ole oikeassa muodossa"],
      required: [(v) => !!v || "Pakollinen kenttä"],
      positiveNumber: [(v) => v > 0 || "Anna positiivinen luku"],
      globalValues,
    };
  },

  mounted() {
    if (this.$route.query.state === "vuokranet") {
      // Querier info
      this.data.querier_name = this.$store.state.account.currentUser.name;
      this.data.querier_ssn = this.$store.state.account.currentUser.social;

      // Tenant info
      this.isCompany = this.$route.query.isCompany == "true" ? true : false;
      this.data.govid = this.$route.query.govid;
    } else {
      // Querier info
      this.data.querier_name = this.$store.state.account.currentUser.name;
      this.data.querier_ssn = this.$store.state.account.currentUser.social;
    }
  },

  watch: {
    "data.govid"() {
      if (this.data.govid) this.data.govid = this.data.govid.toUpperCase();
    },

    "data.querier_ssn"() {
      if (this.data.querier_ssn) this.data.querier_ssn = this.data.querier_ssn.toUpperCase();
    },

    async tab() {
      if (this.tab == 1) {
        let res = await axiosMethods.get("/api/v1/ropo24/credit-reports");
        this.reports = res.data.reports;
      }
    },
  },

  methods: {
    ...mapMutations("pdfViewer", ["openViewer"]),

    async downloadItem(item) {
      try {
        const res = await apiAgent({
          method: "GET",
          url: "/api/v1/ropo24/downloadCreditReport/" + item.reportId,
          responseType: "blob",
        });

        this.openViewer({
          data: res.data,
          name: `${this.formatDate(item.createdAt)}_raportti__${item.govid}.pdf`,
        });
      } catch (err) {
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },

    async submit() {
      if (this.$refs.form.validate()) {
        if (confirm("Luottotietojen haku on maksullista. Haluatko varmasti jatkaa?")) {
          try {
            const res = await apiAgent({
              method: "POST",
              url: "/api/v1/ropo24/createCreditReport",
              data: { ...this.data, isCompany: this.isCompany },
              responseType: "blob",
            });

            this.openViewer({
              data: res.data,
              name: `Raportti_${this.data.govid}.pdf`,
            });
          } catch (err) {
            let error = await axiosMethods.validateRequest(err);
            this.showPopup(error, "error");
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.v-input.v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
